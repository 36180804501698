"use strict"

class AnyBasket {
    static get inst() {
        if(!this._inst) {
            if(!this.instClass) {
                throw new Error("No basket service loaded!")
            }
            this._inst = new this.instClass()
        }
        return this._inst
    }
    /**
     * @param {typeof AnyBasketInst} c
     */
    static register(c) {
        this.instClass = c
    }
}

class AnyBasketInst {
    /** @type {string} eg "GBP" */
    get currency() {
        throw new Error("Not implemented")
    }
    /**
     * @type {AnyBasketItemInst[]}
     */
    get itemsFlat() {
        throw new Error("Not implemented")
    }
    /**
     * @returns {?string}
     */
    get ref() {
        throw new Error("Not implemented")
     }
    /**
     * @returns {number}
     */
    get total() {
       throw new Error("Not implemented")
    }
    /**
     * @param {BasketItemSpec} item
     * @returns {Promise}
     */
    async addItem(item) {
        throw new Error("Not implemented")
    }
    /**
     * @param {AnyBasketItemInst} item
     * @returns {Promise<boolean>}
     */
    async removeItem(item) {
        throw new Error("Not implemented")
    }
    reloadContent() {
        throw new Error("Not implemented")
    }
}
class AnyBasketItemInst {
    /** @type {string} eg "GBP" */
    get currency() {
        throw new Error("Not implemented")
    }
    /** @type {string} */
    get label() {
        throw new Error("Not implemented")
    }
    /**
     * @type {number}
     */
    get periodMonths() {
        throw new Error("Not implemented")
    }
    /** @type {number} Price excluding VAT */
    get price() {
        throw new Error("Not implemented")
    }
    /**
     * @param {string} name
     * @returns {boolean}
     */
    matchesIdentity(name) {
        throw new Error("Not implemented")
    }
}
